

// HEAD DATA
export const headData = {
  title: 'Phone Coaching - Warragul and surrounding areas', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Phone coaching services for Warragul and surrounding areas', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Holly',
  subtitle: 'I believe practice makes perfect, when it comes to phones.',
  cta: 'Lets practice!',
};

// ABOUT DATA
export const aboutData = {
  img: 'phone.jpg',
  paragraphZero: 'Picture this:',
  paragraphOne: `You've just received a new phone, possibly from a well meaning relative, but you don't know how to use it.
  You see others glued to their phones, but your phone is a little different than theirs. You follow the well intentioned instructions,
  but something goes wrong and you don't know where to go from there. `,
  paragraphTwo: 'Does this sound familiar?',
  paragraphThree: `I started this little phone coaching side business after realizing many people didn't get the support or practice they need
  to get comfortable with their phones. I've been helping family and friends get oriented online for years, now I'd like to help you too.`,
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: 'calls',
    img: 'phonecall.jpg',
    title: 'Practice Phone Calls',
    info: `We'll practice calling each other. Answering the phone when its asleep, awake, in use.`,
    info2: `I'll lengthen the ring tone length, making answering the phone less of a bothersome rush.`,
    //  url: '',
    //  repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: 'sms',
    img: 'mms.jpg',
    title: 'SMS and MMS',
    info:
      'Trying to show off your family and hobbies to your friends, but the pictures always get lose in transit?',
    info2: `We'll practice sending texts, images, videos and links back and forth.`,
    //  url: '',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: 'social',
    img: 'socialmedia.jpg',
    title: 'Join the Conversation',
    info: `Social media is getting more prevalent, and more complicated, every year. So now's the best time to learn!`,
    info2:
      'Practice signing up, logging in, tweaking your privacy settings and most importantly, messaging. No on wants that friends only message going public!',
    //  url: '',
    //  repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

export const priceData = {
  price: 50,
};

export const locationData = {
  text: [
    'In the convenience of your home, or a local cafe you like, I will coach you on any difficulties you have with your phone or the internet.',
    `I tutor people in Warragul and surrounding areas. This includes Drouin, Yarragon and Neerim South.`,
  ],
};

export const reviewData = [
  {
    text: 'Holly has the perfect personality for teaching. She is very patient.',
    name: 'Shirley',
    score: 4.5
  },
  {
    text: 'Very kind and patient tutoring with easy to follow written instructions.',
    name: 'Barbara',
    score: 5
  },
  {
    text: 'Without Holly I would have been really struggling. Very patient.',
    name: 'Marie',
    score: 5
  }
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: 'fb',
      name: 'facebook',
      url: 'https://www.facebook.com/groups/753195215582989',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
